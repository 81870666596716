<template>
  <div class="page-container">
    <el-button type="primary" @click="openExamSystem">打开课堂考核模块</el-button>
  </div>
</template>

<script>
import {SchoolModel} from "@/model/exp/SchoolModel";
import {CommonModel} from "@/model/CommonModel";
import {loginExe, programAddScore} from "@/api/exp/TestApi";
import {msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";

export default {
  name: "teacherCddxExam",
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      tabShow:"doc"
    }
  },
  mounted() {

  },
  methods: {
    openExamSystem(){
      window.open('http://czyexam.cdzyhd.com/')
    }
  }
}
</script>

<style scoped>

</style>
