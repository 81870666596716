import {getList, save, update} from "@/api/exp/SchoolApi";
import {CommonModel} from "@/model/CommonModel";
import {msg_err} from "@/utils/ele_component";

/**
 学校model
 */
class SchoolModel {
  // 获取学校列表
  static async getList(page, size, query) {
    let params = {
      page: page,
      size: size,
    }
    params = Object.assign(params, query)
    let [data] = await getList(params)
    if (size === 0) {  // 不分页
      return data.data;
    } else { // 分页
      let list = CommonModel.generateListMybatisPlus(data.data)
      // 遍历config转换为对象
      let listData = list[0]
      return [listData, list[1]];
    }
  }
}

export {SchoolModel}
