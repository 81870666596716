import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

export async function loginExe(param) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/ums/loginExe`, "post_json", param);
}

export async function programAddScore(param) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/ums/programAddScore211104`, "post_json", param);
}
